.Order {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.recordContainer {
  text-align: right;
}

.record {
  width: 100%;
  object-fit: cover;
  box-shadow: 0px 4px 195px -43px hsla(183, 63%, 76%, 0.1);
  transition: all 0.2s;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.title {
  font-size: 36px;
  font-weight: 300;
  transition: all 0.2s;
  opacity: 0.8;
}

.cta {
  cursor: pointer;
  border: 2px solid hsl(47, 56%, 84%);
  background: transparent;
  font-size: 24px;
  font-weight: 500;
  color: hsl(47, 56%, 84%);
  padding: 16px;
  transition: all 0.2s;
  opacity: 0.8;
}

.Order:hover .title,
.Order:hover .cta {
  opacity: 1;
}

.Order:hover .record {
  box-shadow: 0px 4px 195px -43px hsla(183, 63%, 76%, 0.15);
}

@media (min-width: 768px) {
  .Order {
    flex-wrap: nowrap;
  }

  .record {
    width: 600px;
    height: 600px;
    object-fit: cover;
    box-shadow: 0px 4px 195px -43px hsla(183, 63%, 76%, 0.1);
    margin: 0 32px 0 0;
  }

  .title {
    font-size: 48px;
  }

  .cta {
    font-size: 36px;
  }
}

@media (min-width: 1200px) {
  .textContainer,
  .recordContainer {
    width: 50vw;
  }

  .record {
    margin: 0;
  }
}