.Social {
  display: none;
  flex-wrap: wrap;
}
.footer .Social {
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 64px 0 0 0;
}

.Social a {
  opacity: 0.8;
  margin: 0 16px 0 0;
  display: inline-block;
}

.Social a:last-child {
  margin: 0;
}

.Social a:hover {
  opacity: 1;
}

.Social a img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (min-width: 768px) {
  .Social {
    display: block;
    position: fixed;
    top: 16px;
    right: 16px;
  }

  .footer .Social {
    display: none;
  }
}