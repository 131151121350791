.Header {
  text-align: center;
  margin: 0 0 24px;
}

#MaggieCarson {
  height: 24px;
}

.cls-1 {
  fill: hsl(47, 56%, 84%);
}

@media (min-width: 768px) {
  .Header {
    text-align: left;
    margin: 0;
    position: fixed;
    top: 16px;
    left: 16px;
  }
}
