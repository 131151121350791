@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;500&display=swap');

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  background: hsla(183, 63%, 3%, 1);
  color: hsl(47, 56%, 84%);
  font-family: 'Ubuntu', sans-serif;
  font-weight: 300;
}

a {
  color: hsl(47, 56%, 84%);
}

.App {
  padding: 16px 16px 48px;
}

@media (min-width: 768px) {
  .App {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}